
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';

//@ts-ignore
import braintree from 'braintree-web';

//@ts-ignore
import {StripeElementCard} from "@vue-stripe/vue-stripe";
import Company from "@/models/Company";
import PaymentRepository from "@/api/repositories/PaymentRepository";

interface ClientOptions {
  clientSecret?: string
}

@Component({
  components: {
    StripeElementCard,
  }
})
export default class AddCreditCardComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private company!: Company;

  /**
   * public key for stripe
   * @private
   */
  private stripePublicKey = process.env.VUE_APP_STRIPE_PK;

  /**
   * is called when user tapped on submit button, tries to submit the stripe credit card form field
   * @private
   */
  private submitCreditCardForm() {
    //@ts-ignore
    this.$refs.elementRef.submit();
  }

  private clientOptions: ClientOptions = {};

  /**
   * gets the current locale to show the vue stripe card element in correct language
   * @private
   */
  private get locale() {
    return this.$i18n.locale;
  }

  /**
   * creates setup intent to get stripe client secret
   * @private
   */
  private async created() {
    const {data} = await PaymentRepository.setupIntent('STRIPE', this.company.id);
    this.clientOptions = {
      clientSecret: data.clientSecret
    };
  }

  /**
   * flag that indicates if the dialog is loading
   * @private
   */
  private isLoading = false;

  /**
   * is called when the token was created by stripe, try to create payment method by the created token
   * @param token
   * @private
   */
  private async tokenCreated(token: any) {
    try {
      // sets loading flag
      this.isLoading = true;

      // takes the token and client secret to set the payment method for the user
      //@ts-ignore
      await this.$stripe.confirmCardSetup(this.clientOptions.clientSecret, {
        payment_method: {
          card: {
            token: token.id
          }
        }
      });

      this.dismiss(true);
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.ADD_CREDIT_CARD.SUCCESS');
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ADD_CREDIT_CARD.ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('closeDialog', deleted);
  }
}
